<template>
  <div id="user_trainings">
    <b-container :fluid="true">
      <b-table :current-page="current_page"
               :fields="fields"
               :items="user.trainings"
               :per-page="per_page"
               head-variant="light"
               hover
               striped
               thead-class="text-center">

        <template v-slot:top-row="{fields}">
          <proposal_filter :id="id"
                           v-model="user.trainings"
                           :fields="fields"
                           :not_include_key="['actions','ordinalNumber']"/>
        </template>

        <template v-slot:cell(ordinalNumber)="row">
          <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
        </template>

        <template v-slot:cell(states.state)="row">
          <span v-text="statusMessage(row.item.states.state)"/>
        </template>

        <template v-slot:cell(actions)="row">
          <template v-if="hasHistory(row)">
            <base-action-expand-details :b-table-row="row"
                                   :title="detailsTitle"/>
          </template>
        </template>

        <template v-slot:row-details="row">
          <b-table :fields="consent_fields"
                   :items="row.item.states.history"
                   hover
                   striped>

            <template v-slot:cell(state)="row">
              <span v-text="stateMessage(row.item.state)"/>
            </template>
          </b-table>
        </template>
      </b-table>

      <b_table_footer v-model="loading"
                      :currentPage="current_page"
                      :data_length="user_trainings_length"
                      :perPage="per_page"
                      @change_page="change_page"/>
    </b-container>
  </div>
</template>

<script>
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import b_table_footer from '@/components/b_table_footer';
  import proposal_filter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'user_trainings',
    components: {
      BaseActionExpandDetails,
      proposal_filter,
      b_table_footer,
      BaseColumnOrdinalNumber,
    },
    data() {
      return {
        selected_card: 1,
        id: 1,
        user: {},
        loading: true,
        current_page: 1,
        per_page: 5,
        fields: [
          ordinalNumber,
          {
            key: 'training_id',
            label: 'ID',
            sortable: true,
          },
          {
            key: 'training_name',
            label: 'Training name',
            sortable: true,
          },
          {
            key: 'states.date',
            label: 'Date',
            sortable: true,
          },
          {
            key: 'states.state',
            label: 'Status',
            sortable: true,
          },
          'actions',
        ],
        consent_fields: [
          {
            key: 'state',
            label: 'State',
            sortable: true,
          },
          {
            key: 'date',
            label: 'Date',
            sortable: true,
          },
        ],
        disabled: true,
        detailsTitle: {
          hidden: 'Show history',
          shown: 'Hide history',
        },
      };
    },
    computed: {
      full_name() {
        return `${this.user.first_name} ${this.user.last_name}`;
      },
      user_trainings_length() {
        const { trainings = [] } = this.user;
        return trainings.length;
      },
    },
    created() {
      this.load_data();
    },
    methods: {
      hasHistory(row) {
        return row.item.states.history.length > 0;
      },
      change_page(val) {
        this.current_page = val;
      },
      load_data() {
        const user_id = this.$route.params.id;
        this.axios.get(`/users/${user_id}/trainings`)
          .then((response) => {
            this.user = response.data;
            this.$emit('setup_proposal', this.user.trainings);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
      statusMessage(state) {
        return state ? 'Active' : 'Inactive';
      },
      stateMessage(state) {
        return state ? 'Acquired' : 'Resigned';
      },
    },
  };
</script>

<style scoped>
</style>
