<template>
  <div id="user_affiliations">
    <b-container :fluid="true">
      <b-table :current-page="current_page"
               :fields="fields"
               :items="userAffiliations"
               :per-page="per_page"
               head-variant="light"
               hover
               striped
               thead-class="text-center">

        <template v-slot:top-row="{fields}">
          <proposal_filter :id="id"
                           v-model="userAffiliations"
                           :fields="fields"
                           :not_include_key="['actions','ordinalNumber']"/>
        </template>

        <template v-slot:cell(ordinalNumber)="row">
          <base-column-ordinal-number v-bind="{index: row.index, perPage: per_page, currentPage: current_page}"/>
        </template>

        <template v-slot:cell(actions)="row">
          <base-action-expand-details :b-table-row="row"/>
        </template>

        <template v-slot:row-details="row">
          <b-card>
            <affiliation-form v-model="row.item"
                              :validator="validator"
                              is_operator
                              lazy/>
          </b-card>
        </template>
      </b-table>

      <b_table_footer v-model="loading"
                      :currentPage="current_page"
                      :data_length="user_affiliations_length"
                      :perPage="per_page"
                      @change_page="change_page"/>
    </b-container>
  </div>
</template>

<script>
  import { affiliation_validator } from '@/assets/validators/affiliation_validator';
  import BaseActionExpandDetails from '@/components/actions/BaseActionExpandDetails';
  import b_table_footer from '@/components/b_table_footer';
  import AffiliationForm from '@/components/AffiliationForm';
  import proposal_filter from '@/components/proposal_filter';
  import BaseColumnOrdinalNumber from '@/components/baseComponents/BaseColumnOrdinalNumber';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'user_affiliation',
    components: {
      BaseActionExpandDetails,
      proposal_filter,
      b_table_footer,
      BaseColumnOrdinalNumber,
      AffiliationForm,
    },
    data() {
      return {
        validator: affiliation_validator,
        loading: true,
        id: 1,
        userAffiliations: [],
        selected_card: 1,
        current_page: 1,
        per_page: 5,
        fields: [
          ordinalNumber,
          {
            key: 'organization.name',
            label: 'Organization name',
            sortable: true,
          },
          {
            key: 'organization.city',
            label: 'Organization city',
            sortable: true,
          },
          {
            key: 'organization.country',
            label: 'Organization country',
            sortable: true,
          },
          {
            key: 'states.name',
            label: 'State',
            sortable: true,
          },
          'actions',
        ],
      };
    },
    computed: {
      user_affiliations_length() {
        return this.userAffiliations.length;
      },
    },
    created() {
      this.load_data();
    },
    methods: {
      change_page(val) {
        this.current_page = val;
      },
      load_data() {
        const userId = this.$route.params.id;
        this.axios.get(`/affiliations/${userId}`)
          .then((response) => {
            this.userAffiliations = response.data;
            this.$emit('setup_proposal', this.userAffiliations);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style scoped>
</style>
