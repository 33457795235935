<template>
  <div id="user_details">
    <b-container>
      <b-card bg-variant="light"
              header="User details"
              header-bg-variant="dark"
              header-text-variant="white">

        <b-row v-for="field in user_fields"
               :key="field.name"
               class="my-1">

          <b-col sm="3">
            <label :for="field.id"
                   v-text="field.name"/>
          </b-col>

          <b-col sm="9">
            <b-form-input :id="field.id"
                          v-model="field.value"
                          :readonly="readonly"
                          type="text"/>
          </b-col>
        </b-row>

        <b-tabs v-model="selected_card"
                card>
          <b-tab v-for="tab in tabs"
                 :key="tab.component"
                 :title="tab.title"
                 lazy>
            <component :is="tab.component"/>
          </b-tab>
        </b-tabs>

      </b-card>
    </b-container>
  </div>
</template>

<script>
  import user_consents from '@/components/operational/users/user_details/user_consents';
  import user_trainings from '@/components/operational/users/user_details/user_trainings';
  import user_affiliations from '@/components/operational/users/user_details/user_affiliation';

  export default {
    name: 'user_details',
    components: {
      user_consents,
      user_trainings,
      user_affiliations,
    },
    data() {
      return {
        id: 1,
        selected_card: 0,
        user: {},
        loading: true,
        per_page: 5,
        per_page_details: 5,
        tabs: [
          {
            title: 'Consents history',
            component: 'user_consents',
          },
          {
            title: 'Trainings history',
            component: 'user_trainings',
          },
          {
            title: 'Affiliations',
            component: 'user_affiliations',
          },
        ],
        readonly: true,
      };
    },
    computed: {
      full_name() {
        return `${this.user.first_name} ${this.user.last_name}`;
      },
      user_fields() {
        return [
          {
            id: 'full_name',
            name: 'Full name',
            value: this.full_name,
          },
          {
            id: 'email',
            name: 'Email',
            value: this.user.email,
          },
          {
            id: 'country_of_origin',
            name: 'Country of origin',
            value: this.user.country_of_origin,
          },
          {
            id: 'gender',
            name: 'Gender',
            value: this.user.gender,
          },
        ];
      },
    },
    created() {
      this.load_data();
      this.selected_card = parseInt(this.$route.params.selected_card, 10);
    },
    methods: {
      load_data() {
        const user_id = this.$route.params.id;
        this.axios.get(`/users/${user_id}`)
          .then((response) => {
            this.user = response.data;
            this.$emit('setup_proposal', this.user);
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container {
    max-width: 100%;
    @media (min-width: 992px) {
      max-width: 80%;
    }
  }

  .tab-pane {
    max-width: 100%;
    padding: 0;
  }
</style>
